import React from 'react'
import { scroller } from 'react-scroll'
import '../css/Header.css'

import HoppingerLogo from '../images/hoppinger-logo.svg'

type HeaderProps = {
  size: 'max' | 'mini'
}

const scrollTo = () => {
  scroller.scrollTo('scroll-to-me', {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart'
  })
}

export const Header = (props: HeaderProps): JSX.Element =>
  props.size === 'max'
    ? (
      <>
      <header className="App-header__max">
        <div id="header__title"><p id="header__title__text">Internship @ </p><img className="header__hoppinger-logo header__hoppinger-logo--safari_only" src={HoppingerLogo} alt="Hoppinger" /></div>
        <span id="header__date">August 2019 - December 2019</span>
        <button id="header__button" onClick={() => scrollTo()}>See what's it about</button>
        <div id="header__profile">
          <img id="header__image" src="https://avatars3.githubusercontent.com/u/29234113?v=4" alt="Amar Kisoensingh" />
          <span id="header__name">Amar Kisoensingh</span>
        </div>
      </header>
      </>
    )
    : (
      <header className="App-header__mini">
      </header>
    )