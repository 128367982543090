import React from 'react'
import { Link } from 'react-router-dom'
import { Notice } from '../../components/notice'
import { Competence } from '../index'
import { Image } from '../../components/image'

import SDBusinessAccount from './images/Business accounts - SD (0).jpg'

type O1Props = {
  linked_competences: Competence['linked_competence']
}

export const O1 = (p: O1Props) => (
  <>
    <section>
      <h1>Context</h1>
      <p> 
        Business accounts are a type of account <strong>De Beren</strong> wantend to support. These are a
        type of account already registered users can request so they can pay as a company.
      </p>
      <p>
        The flow of requesting a business account is as follows:
        <ol>
          <li>Already registered user requests a business account</li>
          <li>Franchise owner gets an email with the details of the user</li>
          <li>Franchise owner can decide if he or she accepts the business account</li>
          <li>If the franchise owner accepts, he or she has to create the account by hand in the system</li>
        </ol>
      </p>
      <Notice type={'info'}>
        This competence is linked with {p.linked_competences !== undefined ? p.linked_competences.name : '#'}. Check <Link to={p.linked_competences !== undefined ? p.linked_competences.to : '#'}>{p.linked_competences !== undefined ? p.linked_competences.name : '#'}</Link> for the validation of the design.
      </Notice>
    </section>
    <section>
      <h1>Evidence</h1>
      <p>
        At this point, I've already worked a bit on the implementation of <strong>Selligent</strong>, which is our
        email service. The business accounts do interact with Selligent, so I had already an idea how the business accounts
        were going to be implemented.
      </p>
      <p>
        I made the initial sequence diagram for the business accounts as you can see in figure 1.
      </p>
      <Image name='sd-0' src={SDBusinessAccount} caption='Figure 1. Initial sequence diagram of the business accounts.' />
    </section>
    <section>
      <h1>Feedback</h1>
      <p>
        Cristina thought I made the sequence diagram pretty well, two things she missed were <strong>marked</strong> human
        actors and a consistent flow (left to right, up to down). 
      </p>
    </section>
    <section>
      <h1>Reflection</h1>
      <p>
        Not marking human actors is a rookie mistake I made. You're supposed to mark them in sequence diagrams.
        I don't think that there's something wrong with the flow of the diagram, but that just goes to show
        that it may not be confusing to me but could be to others.
      </p>
    </section>
  </>
)