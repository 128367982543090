import React from 'react'
import { getAppendixLink } from '../index'
import { Notice } from '../../components/notice'
import { Link } from 'react-router-dom'
import { Competence } from '../index'
import { Code } from '../../components/code'

import { code } from './code'

type R2Props = {
  linked_competences: Competence['linked_competence']
}

export const R2 = (p: R2Props) => (
  <>
  <section>
    <h1>Context</h1>
    <p>
      Since a load test for De Beren was planned and I had to test for this competence, it seemed
      only natural that I would devise a plan for the test. I also never executed a load test, so
      it was the perfect opportunity for me under the guidance of Cristina.
    </p>
    <Notice type={'info'}>
      This competence is linked with AN2. Check <Link to={p.linked_competences !== undefined ? p.linked_competences.to : '#'}>AN2</Link> for the load test plan.
    </Notice>
  </section>
  <section>
    <h1>Evidence</h1>
    <p>
      First, I had to create the script that I was going to use to load test. This was by using Firefox
      to record all the actions specified in the load test plan and import them in the Gatling software
      to generate Scala code. The Scala code as seen in code example 1 could then be run and would
      generate a report like <a target="blank" href={getAppendixLink('Realize')('R2')('Gatling - Result 1')}>this one</a>.
    </p>
    <Code caption={`Code example 1. Scala script snippet that's used to load test.`} lang='scala'>{code.script_snippet}</Code>
    <p>
      Next, I got to work on preparing for the load test. That included settings up the Google Sheet for
      the results of Grafana and to download the results from the test server.
    </p>
    <p>
      After each test, I exported the results from Grafana to CSV files. I coudn't copy/paste the results
      into Google Sheets because the times in the CSV files had a <code>.</code> instead of an <code>,</code>.
      So I had to replace the <code>.</code> and manually format the cells in the Sheet, which took some
      time but wasn't that bad. Without this fix, I coudn't generate diagrams, so I kinda had to do it 🙃.
      You can check out the result of Grafana <a target="blank" href={getAppendixLink('Realize')('R2')('De Beren - Load test - Results')}>here</a>.
    </p>
    <p>
      Lucky for me, Gatling automatically generates reports after each test. Here are the results from Gatling:
      <ul>
        <li><a target="blank" href={getAppendixLink('Realize')('R2')('Gatling - Result 1')}>Test 1</a></li>
        <li><a target="blank" href={getAppendixLink('Realize')('R2')('Gatling - Result 2')}>Test 2</a></li>
        <li><a target="blank" href={getAppendixLink('Realize')('R2')('Gatling - Result 3')}>Test 3</a></li>
      </ul>
      You might notice that the results don't look so good as you get to end of the tests.
    </p>
  </section>
  <section>
    <h1>Feedback </h1>
    <p>
      The preparation was good, but as you can see in the <a target="blank" href={getAppendixLink('Realize')('R2')('R2 - Feedback - Google Form')}>feedback</a> from
      Cristina, it was not clear in the results where the treshold of the application was. She also wanted a follow up
      with why the test showed so many <code>500</code>'s.
    </p>
  </section>
  <section>
    <h1>Reflection</h1>
    <p>
      Performing the test was a breeze, expect for the last test. The K8 pods kept crashing
      because the load was too much, so there is no data on test 4. I had to redo
      the first two tests because the method on how I gathered data was not consistent,
      but I eventually found a way.
    </p>
    <p>
      I noticed that the third test wasn't really successful which I did point out 
      to Cristina. We concluded that the crashes were the result of the RAM restrictions
      the server has and that the dotnet application wasn't aware of this. I wished I 
      did a better job of investigating, but that wasn't part of the task I was given.
    </p>
    <p>
      I completely agree with Cristina that I didn't draw a good enough conslusion on why 
      some of the requests failed. I can do so now, but I shoud've put it in a report
      or scheduled a meeting with Cristina to discuss the findings. I don't agree with her that I
      should've done a follow up on the <code>500</code>'s. I think it's more of a second test
      than that's it is apart of this load test.
    </p>
  </section>
  </>
)