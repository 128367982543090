import React from 'react'
import { getAppendixLink } from '../index'
import { Image } from '../../components/image'

import Evidence2 from './images/evidence-2.png'

export const B1 = () => (
  <>
    <section>
      <h1>Context</h1>
      <p>
        I had to provide a plan in which was detailed how I would be proving the learning
        goals at the start of the internship.
      </p>
    </section>
    <section>
      <h1>Evidence</h1>
      <p>
        I wrote a <a target="blank" href={getAppendixLink('Manage & Control')('B1')('Stageverslag')}>plan</a> and,
        with the approval of my school supervisor, carried it out. I looked with my company supervisor which learning goals
        I could do in the project (De Beren) that she was also working in. Then, I sat down with my 
        project manager and he scheduled people to sit down with me who I needed to complete the learning goal.
      </p>
      <p>
        I didn't have a problem completing the learning goals and wasn't short on time. As you can see in figure 1,
        I worked the minimal 640 hours required to complete the internship.
      </p>
      <Image name="worked-hours" src={Evidence2} caption="Figure 1. Evicence that I worked the minimal 640 hours." />
    </section>
    <section>
      <h1>Feedback</h1>
      <p>
        -
      </p>
    </section>
    <section>
      <h1>Reflection</h1>
      <p>
        Drafting the plan was a really good idea. I had less worries knowing when and how I would be doing the learning
        goal versus not having a plan at all.
      </p>
    </section>
  </>
)