import React from 'react'
import { Link } from 'react-router-dom'

import '../css/CompetenceCard.css'

type CompetenceCardProps = {
  title: string
  to: string
}

export const CompetenceCard = (props: CompetenceCardProps): JSX.Element => (
  <Link className="cc-container" to={props.to}>
    <span>{props.title}</span>
  </Link>
)