import React from 'react'
import { Notice } from '../../components/notice'
import { Link } from 'react-router-dom'
import { Competence } from '../index'
import { getAppendixLink } from '../index'

type AN2Props = {
  linked_competences: Competence['linked_competence']
}

export const AN2 = (p: AN2Props) => (
  <>
    <section>
      <h1>Context</h1>
      <p>
      I will perform a load test on a (part of a) system to determine how stable the system is
      and to gain insight into when the system is failing and where the limit is. I do this
      through a well-prepared stress test plan that has been approved by an authorized person
      (Jan Metten). During and after the test I will document the process and results.
      </p>
      <p>
      The reason I worked with Jan Metten is that he has performed multiple load tests for
      other projects in the past, so he already has experience with load testing.
      </p>
      <Notice type={'info'}>
        This competence is linked with R2. Check <Link to={p.linked_competences !== undefined ? p.linked_competences.to : '#'}>R2</Link> for the execution of
        the test plan.
      </Notice>
    </section>
    <section>
      <h1>Evidence</h1>
      <p>
        I drafted a test plan for this competence. We went with a load test because that fitted 
        in De Beren project and I've never done one before. The test plan is pretty straight
        forward; I discribed the tools I plan to use, the different scenarios and the results 
        of the tests. I've also explained how I prepared the environments and data about the 
        usage of the former De Beren website which will be used as a basis for the tests.
      </p>
      <p>
        The two main tools are Gatling and Grafana. Gatling is a scripting framework in written 
        in Scala in which you can define actions the script can take based on what the response 
        is from the server you're testing against. Grafana is a monitoring tool, and keeps track of 
        how many responses the server got, which type of response is sends back and the load on 
        the server.
      </p>
      <p>
        I talked with Jan Metten about how he handled a load test for other projects. He gave 
        me a starting point and things he wanted to see in the plan. 
      </p>
      <p>
        You can find the load test plan <a target="blank" href={getAppendixLink('Analyze')('AN2')('De Beren - Load test plan')}>here</a>.
      </p>
    </section>
    <section>
      <h1>Feedback</h1>
      <p>
        Jan Metten was overall happy with the load test plan, except for a few miscalculations 
        with the expected load and the numbers I was going to test with. He also pointed this out 
        in a meeting.
      </p>
      <p>
        You can find the feedback form <a target="blank" href={getAppendixLink('Analyze')('AN2')('AN2 - Feedback - Google Forms')}>here</a>.
      </p>
    </section>
    <section>
      <h1>Reflection</h1>
      <p>
        The plan was not that complicated. The only thing that was hard was choosing which
        scenarios were the best to test, because there were multiple and I didn't have time to
        test them all. Jan Metten remarks were correct, the only thing that was off were my
        calculations. It was good that I prepared a plan, because this prevented wasting time
        and during the test, I could always reference back to the plan.
      </p>
      <p>
        I modified the numbers that I was planning to use in the test, added in the plan what I
        was going to gather as results and made the Google Sheet in preparation.
      </p>
    </section>
  </>
)