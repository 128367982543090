import React from 'react'
import { Image } from '../../components/image'
import { getAppendixLink } from '../index'
import AppcenterArch from './images/msap-push-notifications.png'
import FCMArch from './images/fcm-push-notifications.png'

export const L1 = () => (
  <>
    <section>
      <h1>Context</h1>
      <p>
        One of our clients, VPS (Veritas Petrolium Services), wanted an native app for Android and
        iOS in addition to the web portal Hoppinger has build.
      </p>
      <p>
        VPS is a company that samples and inspects fuel from tankers and generates a report
        with the quality of the oil and if the client needs to take action. Besides the
        <strong> Reports</strong>, VPS also generates <strong>Bunker Alerts</strong>, which are
        newsletters about important things that are relevant at the time.
      </p>
      <p>
        So, for the push notifications to work, we need to support two kinds of notifications:
        <ul>
          <li>Report notifications</li>
          <li>Bunker Alert notifications</li>
        </ul>
      </p>
      <p>
        The <strong>Report notifications</strong> are specific to a user, since the report is for 
        a specific tankers fuel.
      </p>
      <p>
        The <strong>Bunker Alert notifications</strong> are for all the users of VPS.
      </p>
      <p>
        The PO of VPS realized that some users may get "spammed" with notifications, so they also 
        wanted a filter system where the user could toggle notifications for certain tankers.
      </p>
    </section>
    <section>
      <h1>Evidence</h1>
      <p>
        We started researching about push notifications on Android and iOS. The main focus was 
        to research different packages and ways to impelement the architecture. After the first round of 
        research (<a target="blank" href={getAppendixLink('Analyze')('L1')('First round of research')}>First round of research</a>)
        we found several packages we could use:
        <ul>
          <li>react-native-notifications from <strong>Wix</strong></li>
          <li>react-native-push-notifications from <strong>zo0r</strong></li>
          <li>react-native-firebase from <strong>Invertase</strong></li>
          <li>appcenter-sdk-react-native from <strong>Microsoft</strong></li>
        </ul>
      </p>
      <p>
        I took it upon myself to continue and chose the packages from Microsoft because the company
        maintaining the package is well known and the documentation looked promising. To know if this was
        the right approach for us, I needed to get some first-hand experience with it. So after experimenting
        with the package and researching more about the SDK and problems people were having, I wrote a 
        short research document (<a target="blank" href={getAppendixLink('Analyze')('L1')('Second round of research')}>Second round of research</a>).
      </p>
      <p>
        In figure 1, you can see an overview of the implementation of Appcenter from Microsoft. One of the main advantages
        the packages touts is dealing with a single endpoint for both Android and iOS.
      </p>
      <Image className="c-image" name="" src={AppcenterArch} caption="Figure 1. Push notification overview of Appcenter implementation." />
      <p>
        After all the experimenting, we held a meeting and had to decide what we were going to do. Before
        the meeting, I quickly typed up a comparison between Appcenter and Firebase Cloud Messaging (FCM) from Google (<a target="blank" href={getAppendixLink('Analyze')('L1')('Push notifications')}>Push Notifications</a>).
        We concluded that the package from Microsoft wasn't mature enough and that FCM was a safer bet.
      </p>
      <p>
        We initially went with the Microsoft package because of the promised ease of use, but in the end
        it ended up biting us in the butt. As you can see in figure 2, the FCM overview is a bit more
        complicated, since the <strong>VPS backend</strong> has to keep track of the IDs of the accounts.
        It means that we have to make more changes to our backend that the Microsoft package could've taken out of our hands.
      </p>
      <Image name="" src={FCMArch} caption="Figure 2. Push notifications overview of FCM implementation." />
    </section>
    <section>
      <h1>Feedback</h1>
      <p>
        I got feedback in the form of a Google form. I asked a colleague if the research I did was useful and
        how likely the colleague would use my research to continue the implementation.
      </p>
    </section>
    <section>
      <h1>Reflection</h1>
      <p>
        The feedback was positive, although looking back, the research didn't have a conclusion like Cristina mentioned.
        This makes it harder for someone without all the context to quickly make an informed decision. 
      </p>
      <p>
        I send a message to the colleague who was going to recommend me to the Project Manager with a 
        framework (<a target="blank" href="https://expo.io/">Expo</a>) we could consider because it had
        push notifications build in, but he never got back to me which was a bummer to be honest.
      </p>
    </section>
  </>
)