import React from 'react'

import '../css/People.css'

import Jim from '../images/jim.jpg'
import Cristina from '../images/cristina.jpg'
import Stelian from '../images/stelian.jpeg'
import Amar from '../images/amar.jpeg'

type Person = {
  src: string
  name: string
  funct: string
}

const peeps: Person[] = [
  {
    src: Jim,
    name: 'Jim Thijsen',
    funct: 'Project Manager'
  },
  {
    src: Cristina,
    name: 'Cristina Ungureanu',
    funct: 'Company Supervisor / Lead'
  },
  {
    src: Stelian,
    name: 'Stelian Paraschiv',
    funct: 'School Supervisor'
  },
  {
    src: Amar,
    name: 'Amar Kisoensingh',
    funct: 'Intern'
  }
]

export const People = () => {
  return (
    <div className="people--container">
      {peeps.map((person, key) => (<Person key={key} name={person.name} src={person.src} funct={person.funct} />))}
    </div>
  )
}

const Person = ({ src, name, funct }: Person) => (
  <div className="person--container">
    <img className="person--image" src={src} alt={name} />
    <span className="person--name">{name}</span>
    <span className="person--function">{funct}</span>
  </div>
)