import React from 'react'
import { Image } from '../../components/image'
import { getAppendixLink } from '../index'

import TrelloBoard from './images/trello-board.png'
import Flow from './images/flow.png'
import MSPAAllUsers from './images/mspa-poc-all-users.png'
import MSPANewUser from './images/mspa-poc-new-user.png'

export const AD1 = () => (
  <>
    <section>
      <h1>Context</h1>
      <p>
        Hoppinger wants to integrate Microsoft Power Automate (MSPA) into their project, preferably
        with their Scaffolder. Microsoft Power Automate is a tool to automate tasks, for 
        example send an email when a product goes below a certain price.
      </p>
      <p>
        The Scaffolder is an internal tool development by Hoppinger to help with starting 
        and maintaining projects. It generates all the neccesary files and creates files 
        where database changes are defined. The end-goal was to generate code with the Scaffolder
        so that MSPA could interact with the application.
      </p>
      <p>
        I worked with Okan, another intern, on a proof of concept (POC). The task was to create 
        a dotnet application where we could send data to MSPA, define a flow that takes 
        the data, transform it, and insert it in database running on my machine.
      </p>
    </section>
    <section>
      <h1>Evidence</h1>
      <p>
        Okan and I started with a Trello board (figure 1) and divided the tasks. We made 
        the board so we had an overview of what needs to be done, who was working on what
        and how far we are.
      </p>
      <Image name="trello-board" className="c-image" src={TrelloBoard} caption="Figure 1. Trello board with tasks." />
      <p>
        We created a dotnet application where we could create a new user and view all 
        the users in the database. In figure 2, you can see the form for the new user. 
        The data will be send from the dotnet application to MSPA.
      </p>
      <Image name="poc-new-user" className="c-image" src={MSPANewUser} caption="Figure 2. Form to create a new user." />
      <p>
        Once MSPA receives the data, it will insert it into a database. A feature we had to include
        in this POC was type safety. This means that we know at all times what data we have access
        to and what the type is (string, int, boolean). Because we know in the flow that we have a 
        first name and last name, we can insert it into the database properly.
      </p>
      <p>
        You might ask why we make MSPA insert the data into the database and not the dotnet application
        itself. That's because we had to touch all the requirements of the POC. Making MSPA insert data
        into a database we managed was one of them.
      </p>
      <Image name="ad1-flow" className="c-image" src={Flow} caption="Figure 3. Flow in Microsoft Power Automate." />
      <p>
        After MSPA received the data and inserted it into the database, we could query the database and
        ask for all the users. We could finally list them as shown in the table in figure 4.
      </p>
      <Image name="poc-all-users" className="c-image" src={MSPAAllUsers} caption="Figure 4. List of all the users in the database." />
      <p>
        After we were done with the POC, I wrote some advice on how it went and how to proceed with the next steps
        (<a target="blank" href={getAppendixLink('Advice')('AD1')('README')}>README</a>).
      </p>
    </section>
    <section>
      <h1>Feedback</h1>
      <p>
        I asked in the feedback form how well I explained the problem and what the POC is about.
        I also asked how I might improve the explanation and the next steps.
      </p>
    </section>
    <section>
      <h1>Reflection</h1>
      <p>
        I explained in the README (<a target="blank" href={getAppendixLink('Advice')('AD1')('README')}>README</a>) the several
        components of MSPA (triggers and actions), but not what they could be used for. This is something that was 
        missing according to Cristina. Looking back now it's really unclear what the could be used for if you don't 
        have any knowledge about MSPA.
      </p>
      <p>
        The issue with the data gateway is actually a really big one; without it, we can't use our own database directly with
        MSPA. We would have to use existing endpoints in dotnet, which adds another element in the equation, which we'd rather not have.
        I should've said in the advice that it isn't production ready, like Cristina said in het feedback.
      </p>
    </section>
  </>
)