import * as React from 'react'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import HelpRoundedIcon from '@material-ui/icons/HelpRounded'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'

import '../css/Notice.css'

export type NoticeProps = {
  type: 'info' | 'help' | 'warning' | 'error'
}

export const Notice = (p: React.PropsWithChildren<NoticeProps>) => (
  <>
    <div className={`notice-container notice-${p.type}`}>
      <div className='notice-icon-container'>
        {
          p.type === 'info'
          ? <InfoRoundedIcon style={{ color: '#1976d2'}} />
          : p.type === 'help'
          ? <HelpRoundedIcon style={{ color: '#1976d2' }} />
          : p.type === 'warning'
          ? <WarningRoundedIcon style={{ color: '#f57c00' }} />
          : <ErrorRoundedIcon style={{ color: '#d32f2f' }} />
        }
      </div>
      <div className='notice-text-container'>
        {p.children}
      </div>
    </div>
  </>  
)