import React from 'react'
import { Image } from '../../components/image'
import { getAppendixLink } from '../index'

import Backlog from './images/backlog.png'

export const AN1 = () => (
  <>
    <section>
      <h1>Context</h1>
      <p>
        For this competence I had to perform a requirements analysis. I came up with a fictional
        company that want's to build a webportal and encrypt data. Cristina expanded on that with
        the company auditing hardware components, for example hard disks, monitors or motherboards,
        and writing reports for the clients. The company wants to upload the reports to the 
        webportal so that clients can see and / or download the report.
      </p>
    </section>
    <section>
      <h1>Evidence</h1>
      <p>
        Cristina acted as PO for the company and I was supposed to create the user stories. We had 
        two sessions, the first session was for me, so I can get familiar with the project. 
        I wrote down (<strong><a target="blank" href={getAppendixLink('Analyze')('AN1')('First session')}>First session</a></strong>) what I needed to create the user stories and asked follow up
        questions to make the picture clearer. After the sessions, I created the user stories, which you can see in figure 1
        (<strong><a target="blank" href={getAppendixLink('Analyze')('AN1')('AN1 - Requirements Analysis')}>AN1 - Requirements Analysis</a></strong>), and walked with Cristina through it in the second session to verify that I captured correctly what she wanted.
      </p>
      <Image className="c-image" name="" src={Backlog} caption="Figure 1. Backlog that came out of the session." />
    </section>
    <section>
      <h1>Feedback</h1>
      <p>
        During the second session, she also gave me feedback (<strong><a target="blank" href={getAppendixLink('Analyze')('AN1')('Feedback after second session')}>Feedback after second session</a></strong>) on the user
        stories and we discussed different techniques and methods. Finally, Cristina
        filled in the feedback form I prepared (<strong><a target="blank" href={getAppendixLink('Analyze')('AN1')('AN1 - Feedback')}>AN1 - Feedback</a></strong>).
      </p>
    </section>
    <section>
      <h1>Reflection</h1>
      <p>
        I did an OK job at extracting the user stories according to Cristina. The only thing that I 
        could do better next time is to question her reasoning behind the features she wants more.
        If I did that, I would filter out useless features and bring the focus on more important ones.
      </p>
      <p>
        She also thinks that I did a good job at making things clear for the both of us. I think I could 
        do a better job by asking more follow up questions and "visualising" how the particular feature 
        fits in the whole picture of the project and see how this contributes to the premise of the project.
      </p>
      <p>
        I found it hard to keep track of everything and I had some difficulties with maintaining the 
        flow of the session. Ultimately, I had a good idea of what de PO wanted.
      </p>
      {/* <h2>Did you do anything with this feedback?</h2>
      <p>
        I did nothing to improve the requirements analysis after I got the feedback.
      </p> */}
    </section>
  </>
)