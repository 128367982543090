import React from 'react'

import { Header } from '../components/header'
import { Competence } from '../components/competence'
import { useParams } from 'react-router-dom'

type CompetencePageProps = {

}

export const CompetencePage = (props: CompetencePageProps) => {
  let { id } = useParams()
  
  return (
    <>
      <Header size='mini' />
      <main>
        <Competence key={Math.random()} competence={`${id}`} />
      </main>
    </>
  )
}