import React from 'react'
import { Image } from '../../components/image'
import { Code } from '../../components/code'
import { code } from './code'

import CodeComparison from './images/code-comparison.png'

export const R1 = () => (
  <>
    <section>
      <h1>Context</h1>
      <p>
        De Beren has partnered with <strong>Selligent</strong> to send all of the emails. This includes:
        <ul>
          <li>Order confirmation emails</li>
          <li>Registration emails</li>
          <li>Forgot password emails</li>
          <li>Business account request emails</li>
          <li>User account update emails</li>
        </ul>
      </p>
      <p>
        It was my job, under the guidance of Cristina, to implement:
        <ul>
          <li>Gathering of all the necessary details (details of orders, details of an user)</li>
          <li>Create a structure that Selligent expects</li>
          <li>Send the request to send an email to Selligent with all the data</li>
        </ul>
      </p>
      <p>
        For this competence, I'll be focusing on the <strong>order confirmation</strong> emails.
      </p>
    </section>
    <section>
      <h1>Evidence</h1>
      <p>
        Looking at code example 1, you can see the format that Selligent expects from us when we want
        to send an order confirmation email.
      </p>
      <Code caption="Code example 1. Data object for the order confirmation emails provided by Selligent." lang='json'>{code.selligent_example}</Code>
      <p>
        I had to gather all the data from different tables and format it like code example 1. As you can see
        in code example 2, the function <code>OrderConfirmationEmail</code> does several checks and creates the
        correct object.
      </p>
      <p>
        This function just wasn't gonna cut it according to Cristina. It was way too long and you quickly
        lose the overview.
      </p>
      <Code caption="Code example 2. First version of the code that creates the object and sends it to Selligent." lang='csharp'>{code.send_order_conf_first_version}</Code>
      <p>
        So Cristina and I both sat down and pair programmed. She showed me the reason why code example 2 won't hold up and how 
        we were going to improve it. We got a function with 138 lines of code down to 15 by extracting parts that
        we could logically group together and make them their own function, for example:
        <ul>
          <li>User details</li>
          <li>Restaurant details</li>
          <li>Order details</li>
          <li>Order items</li>
          <li>Item extras</li>
        </ul>
        You can see the result in code example 3.
      </p>
      <Code caption="Code example 3. Second version of the code that creates the object and sends it to Selligent." lang='csharp'>{code.send_order_conf_func}</Code>
      <p>
        Here is a visual comparions between the two functions (figure 1).
      </p>
      <Image name="code-comparison" src={CodeComparison} caption="Figure 1. Visual comparions between code example 2 (left) and code example 3 (right)." />
      <p>
        We've broken the function up in several smaller functions. Doing this, we can for example search and focus
        on the function that handles all the restaurant details.
      </p>
    </section>
    <section>
      <h1>Feedback</h1>
      <p>
        Cristina reviewed my code and told me in person that we have to improve the function I made and 
        that we can do it together (pair programming). I gladly took the opportunity and and we
        imporved the code dramatically.
      </p>
    </section>
    <section>
      <h1>Reflection</h1>
      <p>
        This competence is one of the most important ones. I learned a much better way of
        thinking and writing software that I have have adjusted my style of writing. I'm
        really glad that Cristina provided the feedback she gave me.
      </p>
    </section>
  </>
)