import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ScrollToTop } from './components/scrollToTop'
import 'lazysizes';

import './App.css';

import { Main } from './pages/main'
import { CompetenceIndexPage } from './pages/competenceIndex'
import { CompetencePage } from './pages/competence'

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/competence/:id" component={CompetencePage} />
          <Route path="/competence" component={CompetenceIndexPage} />
          <Route path="/" component={Main} />
        </Switch>
      </Router>
      <footer>
        2019{new Date().getFullYear() !== 2019 && ` - ${new Date().getFullYear()}`} | <a href="https://amar.sh">Amar Kisoensingh</a>
      </footer>
    </div>
  );
}

export default App;
