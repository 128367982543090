import React, { useEffect, useState } from 'react'
import { useHistory, Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import { competenceArray, Competence as CompetenceType, CompetenceGroup } from '../competences/index'

import '../css/Competence.css'
import '../css/CompetenceAppendixCard.css'
import { Icon } from '@mdi/react';

type CompetenceProps = {
  competence: string
}

export const Competence = 
(props: CompetenceProps) => {
  const [competence, setCompetence] = useState<CompetenceType | undefined>()
  const [group, setgroup] = useState<string>()
  let history = useHistory()

  useEffect(() => {
    competenceArray.map(async (cg: CompetenceGroup) => {
      let comp: CompetenceType | undefined = cg.c.find(c => c.code === props.competence)
      if (comp !== undefined) {
        setCompetence(comp)
        setgroup(cg.name)
      }
    })
  })


  if (competence !== undefined) {
    return (
      <section id="competence-container">
        <section>
          <div id="competence-back-button-container">
            <IconButton id="competence-back-button" onClick={() => history.goBack()} aria-label="go back">
              <KeyboardBackspaceRoundedIcon />
            </IconButton>
            <span>Go back</span>
          </div>
          <h1>{competence.title}</h1>
          <div className="competence-info-container">
            <span id="competence-info-group">
              {group} • {competence.linked_competence ? `${competence.code} & ${competence.linked_competence.name}` : `${competence.code}`}{competence.personal && (<em> • Personal learning goal</em>)}
            </span>
            <span id="competence-c-title">{competence.competence_title}</span>
            {competence.competence_title_2 && (<><br/><span id="competence-c-title">{competence.competence_title_2}</span></>)}
          </div>
        </section>
        <competence.component linked_competences={competence.linked_competence} />
        {competence.appendix && (
          <section>
            <h1>Appendix</h1>
            <div id='cac-index'>
            {competence.appendix?.map((a, key) => (
              <>
                {a.external_link! ? (
                  <a key={key} target="blank" href={a.external_link!}>
                    <div className='cac-container'>
                      <div className='cac-icon'>
                        <Icon path={a.icon} size={1.75} color="#4a00e0"/>
                      </div>
                      <div className='cac-text-container'>
                        <span className='cac-text-title'>{a.title}</span>
                        <span className='cac-text-subtitle'>{a.subtitle}</span>
                      </div>
                    </div>
                  </a>
                ) : (
                  <Link key={key} target={'blank'} to={a.internal_link! }>
                    <div className='cac-container'>
                      <div className='cac-icon'>
                        <Icon path={a.icon} size={1.75} color="#4a00e0"/>
                      </div>
                      <div className='cac-text-container'>
                        <span className='cac-text-title'>{a.title}</span>
                        <span className='cac-text-subtitle'>{a.subtitle}</span>
                      </div>
                    </div>
                  </Link>
                )}
              </>
              ))}
            </div>
          </section>
        )}
      </section>
    )
  } else {
    return (
      <></>
    )
  }
}