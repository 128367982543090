import { List } from 'immutable'

import { B1 } from './B1'

import { AN1 } from './AN1'
import { AN2 } from './AN2'
import { L1 } from './L1'

import { AD1 } from './AD1'

import { O1 } from './O1'
import { O2 } from './O2'

import { R1 } from './R1'
import { R2 } from './R2'
import { L2 } from './L2'

import { S1S2 } from './S1 & S2'

import { mdiPdfBox } from '@mdi/js'
import { mdiGoogleSpreadsheet } from '@mdi/js'
import { mdiWeb } from '@mdi/js';

export type CompetenceAppendix = {
  title: string
  subtitle: string
  icon: string
  internal_link?: string
  external_link?: string
}

export type Competence = {
  code: string
  linked_competence?: {
    name: string
    to: string
  }
  title: string
  competence_title: string
  competence_title_2?: string
  personal?: boolean
  to: string
  component: any
  appendix?: List<CompetenceAppendix>
}

export type CompetenceGroup = {
  name: string
  c: List<Competence>
}

export const competenceArray = [
  { name: 'Manage & Control', c: List<Competence>([
    { code: 'B1',
      title: '',  
      competence_title: 'You can work according to a predetermined and approved internship plan (including planning) and substantiate any exceptions',
      to: '/competence/B1',
      component: B1,
      appendix: List<CompetenceAppendix>([
        {
          title: 'Stageverslag',
          subtitle: 'PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/B1/Stageverslag%20-%20Amar%20Kisoensingh.pdf'
        },
      ])
    },
  ])},
  { name: 'Analyze', c: List<Competence>([
    { code: 'AN1',
      title: 'Requirement analysis for an auditing company',
      competence_title: 'You can perform a requirement analysis for a (part of a) software system with different stakeholders and you take into account the quality standards that apply at the company',
      to: '/competence/AN1',
      component: AN1,
      appendix: List<CompetenceAppendix>([
        {
          title: 'AN1 - Requirements Analysis',
          subtitle: 'Google Sheet',
          icon: mdiGoogleSpreadsheet,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/AN1/AN1%20-%20Requirement%20Analysis%20-%20Backlog.pdf'
        },
        {
          title: 'First session',
          subtitle: 'PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/AN1/First%20session.pdf'
        },
        {
          title: 'AN1 - Feedback',
          subtitle: 'Google Form - PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/AN1/AN1%20-%20Feedback.pdf'
        },
        {
          title: 'Feedback after second session',
          subtitle: 'PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/AN1/Feedback%20after%20second%20session.pdf'
        }
      ])
    },
    { code: 'AN2',
      linked_competence: {
        name: 'R2',
        to: '/competence/R2'
      },
      title: 'Load testing De Beren',
      competence_title: 'You can prepare a test based on the quality characteristics that apply at the company',
      to: '/competence/AN2',
      component: AN2,
      appendix: List<CompetenceAppendix>([
        {
          title: 'De Beren - Load test plan',
          subtitle: 'PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/AN2/De%20Beren%20-%20Load%20test%20plan.pdf'
        },
        {
          title: 'AN2 - Feedback - Google Forms',
          subtitle: 'Google Forms - PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/AN2/AN2%20-%20Feedback%20-%20Google%20Forms.pdf'
        }
      ])
    },
    { code: 'L1',
      title: 'Push notifications on Android and iOS',
      competence_title: 'I want to work with an unknown technology and / or concept by working with it hands-on',
      personal: true,
      to: '/competence/L1',
      component: L1,
      appendix: List<CompetenceAppendix>([
        {
          title: 'First round of research',
          subtitle: 'PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/L1/First%20round%20of%20research.pdf'
        },
        {
          title: 'Second round of research',
          subtitle: 'PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/L1/Second%20round%20of%20research.pdf'
        },
        {
          title: 'Push notifications',
          subtitle: 'PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/L1/PUSH_NOTIFICATIONS.pdf'
        },
        {
          title: 'L1 - Feedback - Google Forms',
          subtitle: 'Google Forms - PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/L1/L1%20-%20Feedback%20-%20Google%20Forms.pdf'
        }
      ])
    },
  ])},
  { name: 'Advice', c: List<Competence>([
    { code: 'AD1',
      title: 'Giving advice on Microsoft Power Automate',
      competence_title: 'You can analyze a practical problem and make a recommendation for it. Make sure that you clearly state what the problem description is, what the substantiated arguments are and what the choice is. Use reliable sources of information (for example, field research and desk research)',
      to: '/competence/AD1',
      component: AD1,
      appendix: List<CompetenceAppendix>([
        {
          title: 'README',
          subtitle: 'PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/AD1/README.pdf'
        },
        {
          title: 'AD1 - Feedback - Google Form',
          subtitle: 'Google Forms - PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/AD1/AD1%20-%20Feedback%20-%20Google%20Forms.pdf'
        }
      ])
    },
  ])},
  { name: 'Design', c: List<Competence>([
    { code: 'O1',
      linked_competence: {
        name: 'O2',
        to: '/competence/O2'
      },
      title: 'Sequence diagram for Business accounts',
      competence_title: 'You can prepare a design for a (part of a) software system and you use existing components and libraries for this',
      to: '/competence/O1',
      component: O1,
      appendix: List<CompetenceAppendix>([
        {
          title: 'O1 - Feedback - Google Form',
          subtitle: 'Google Forms - PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/O1/O1%20-%20Feedback%20-%20Google%20Forms.pdf'
        }
      ])
    },
    { code: 'O2',
      linked_competence: {
        name: 'O1',
        to: '/competence/O1'
      },
      title: 'Validation of the sequence diagram',
      competence_title: 'You can perform a validation for your design',
      to: '/competence/O2',
      component: O2,
      appendix: List<CompetenceAppendix>([
        {
          title: 'O2 - Feedback - Google Form',
          subtitle: 'Google Forms - PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/O2/O2%20-%20Feedback%20-%20Google%20Forms.pdf'
        }
      ])
    },
  ])},
  { name: 'Realize', c: List<Competence>([
    { code: 'R1',
      title: 'Sending emails for De Beren',
      competence_title: 'You can realize software in accordance with the requirements of the assignment and with quality standards as they are used in software engineering or as they are used within the company',
      to: '/competence/R1',
      component: R1,
      appendix: List<CompetenceAppendix>([
        {
          title: 'R1 - Feedback - Google Form',
          subtitle: 'Google Forms - PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/R1/R1%20-%20Feedback%20-%20Google%20Forms.pdf'
        }
      ])
    },
    { code: 'R2',
      linked_competence: {
        name: 'AN2',
        to: '/competence/AN2'
      },
      title: 'Giving De Beren a load they can\'t handle',
      competence_title: 'You use testing',
      to: '/competence/R2',
      component: R2,
      appendix: List<CompetenceAppendix>([
        {
          title: 'De Beren - Load test - Results',
          subtitle: 'Google Sheets',
          icon: mdiGoogleSpreadsheet,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/R2/De%20Beren%20-%20Load%20test%20-%20Results.pdf'
        },
        {
          title: 'Results - Test 1',
          subtitle: 'Google Sheets',
          icon: mdiGoogleSpreadsheet,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/R2/Results%20-%20Test%201.pdf'
        },
        {
          title: 'Results - Test 2',
          subtitle: 'Google Sheets',
          icon: mdiGoogleSpreadsheet,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/R2/Results%20-%20Test%202.pdf'
        },
        {
          title: 'Results - Test 3',
          subtitle: 'Google Sheets',
          icon: mdiGoogleSpreadsheet,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/R2/Results%20-%20Test%203.pdf'
        },
        {
          title: 'Gatling - Result 1',
          subtitle: 'Webpage',
          icon: mdiWeb,
          internal_link: '/results/test-1'
        },
        {
          title: 'Gatling - Result 2',
          subtitle: 'Webpage',
          icon: mdiWeb,
          internal_link: '/results/test-2'
        },
        {
          title: 'Gatling - Result 3',
          subtitle: 'Webpage',
          icon: mdiWeb,
          internal_link: '/results/test-3'
        },
        {
          title: 'R2 - Feedback - Google Form',
          subtitle: 'Google Forms - PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/R2/R2%20-%20Feedback%20-%20Google%20Forms.pdf'
        }
      ])
    },
    { code: 'L2',
      title: 'Improving an internal library',
      competence_title: 'I want to document a library to understand the library better and improve the documentation',
      personal: true,
      to: '/competence/L2',
      component: L2,
      appendix: List<CompetenceAppendix>([
        {
          title: '1. Intro',
          subtitle: 'PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/L2/0.intro.pdf'
        },
        {
          title: '2. Basic components',
          subtitle: 'PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/L2/1.basic_compoments.pdf'
        },
        {
          title: '3. Using components',
          subtitle: 'PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/L2/2.using_components.pdf'
        },
        {
          title: '4. Async',
          subtitle: 'PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/L2/3.async.pdf'
        },
        {
          title: '5. Putting it all together',
          subtitle: 'PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/L2/4.putting_it_all_together.pdf'
        },
        {
          title: 'WFR Slide show',
          subtitle: 'Webpage',
          icon: mdiWeb,
          external_link: 'https://suspicious-brown-b072ab.netlify.com/'
        },
        {
          title: 'L1 - Feedback - Google Form',
          subtitle: 'Google Forms - PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/L2/L2%20-%20Feedback%20-%20Google%20Forms.pdf'
        }
      ])
    },
  ])},
  { name: 'Skills & Attitude', c: List<Competence>([
    { code: 'S1 & S2',
      title: 'Me and Hoppinger working together',
      competence_title:
      `S1: You can familiarize yourself with and adapt to the internship company regarding the method, rules and procedures and you are also able to reflect on this and on your work`,
      competence_title_2:
      `S2: You can carry out agreed work independently (commitment, motivation, respect and the like) and indicate progress and any bottlenecks proactively`,
      to: '/competence/S1 & S2',
      component: S1S2,
      appendix: List<CompetenceAppendix>([
        {
          title: 'S1 & S2 - Feedback - Google Form',
          subtitle: 'Google Forms - PDF',
          icon: mdiPdfBox,
          external_link: 'https://unrulyeon.ams3.digitaloceanspaces.com/amar.sh/internship-2019/S1%20%26%20S2/S1%20%26%20S2%20-%20Feedback%20-%20Google%20Forms.pdf'
        }
      ])
    }
  ])}
]

export const getAppendixLink = (caName: string) => {
  let c = competenceArray.find(ca => ca.name === caName)?.c
  return (code: string) => {
    let appendix = c?.find(c => c.code === code)?.appendix
    return (appendixTitle: string) => {
      let a = appendix?.find(a => a.title === appendixTitle)?.external_link
      let b = appendix?.find(a => a.title === appendixTitle)?.internal_link
      return a !== undefined ? a : b
    }
  }
}