import React from 'react'

import { Header } from '../components/header'
import { CompetenceIndex } from '../components/competenceIndex'

export const CompetenceIndexPage = (): JSX.Element => (
  <>
    <Header size="mini" />
    <main>
      <CompetenceIndex/>
    </main>
  </>
)