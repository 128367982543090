export const code = {
  script_snippet:
`.exec(
  http("[Order] - 2 - Load products page")
    .get("/products?postcode=2222AA")
    .headers(headers_page)
    .basicAuth("USERNAME","PASSWORD")
    .resources(
      http("[Order] - a - Get products")
        .get("/api/v1/odata/GetQuery?Query=Restaurant?$filter=(Active%20eq%20true)&$expand=Restaurant_PostCodeRange($select=Id,RestaurantId,PostCodeRangeId;$expand=PostCodeRange($select=PostCodeStart,PostCodeEnd,Active,DeliveryCost,DeliveryFreeAfterValue,DeliveryMinimumCartValue;$expand=PostCodeRange_PostCodeExclusion($select=Id,PostCodeRangeId,PostCodeExclusionId;$expand=PostCodeExclusion($select=PostCodeStart,PostCodeEnd)))),Restaurant_DeliveryTimes($select=Id,RestaurantId,DeliveryTimesId;$expand=DeliveryTimes($select=Id,MondayDelivery,MondayDeliveryStart,MondayDeliveryEnd,TueDelivery,TueDeliveryStart,TueDeliveryEnd,WedDelivery,WedDeliveryStart,WedDeliveryEnd,ThuDelivery,ThuDeliveryStart,ThuDeliveryEnd,FriDelivery,FriDeliveryStart,FriDeliveryEnd,SatDelivery,SatDeliveryStart,SatDeliveryEnd,SunDelivery,SunDeliveryStart,SunDeliveryEnd)),Restaurant_DeliveryClosingTime($select=Id,RestaurantId,DeliveryClosingTimeId;$expand=DeliveryClosingTime($select=Start,End,Message)),Restaurant_PickupTimes($select=Id,PickupTimesId,RestaurantId;$expand=PickupTimes($select=Id,MondayPickup,MondayPickupStart,MondayPickupEnd,TuePickup,TuePickupStart,TuePickupEnd,WedPickup,WedPickupStart,WedPickupEnd,ThuPickup,ThuPickupStart,ThuPickupEnd,FriPickup,FriPickupStart,FriPickupEnd,SatPickup,SatPickupStart,SatPickupEnd,SunPickup,SunPickupStart,SunPickupEnd)),Restaurant_PickupClosingTime($select=Id,RestaurantId,PickupClosingTimeId;$expand=PickupClosingTime($select=Start,End,Message)),Restaurant_WaitingTimes($select=Id,RestaurantId,WaitingTimesId;$expand=WaitingTimes($select=PickupTimeMin,PickupTimeMax,DeliveryTimeMin,DeliveryTimeMax)),DeliveryRestaurant_DeliveryPayMethod($select=DeliveryRestaurantId,DeliveryPayMethodId;$expand=PayMethod($select=Method,DisplayTitle)),PickUpRestaurant_PickUpPayMethod($select=PickUpRestaurantId,PickUpPayMethodId;$expand=PayMethod($select=Method,DisplayTitle))&$select=Id,Name,Delivery,Pickup,DeliveryCost,DeliveryFreeAfterValue,DeliveryMinimumCartValue,Address")
        .headers(headers_odata)
      .check(status.is(200)),
      http("[Order] - 2b - Get category times")
        .get("/api/v1/odata/GetQuery?Query=Category?$filter=(Active%20eq%20true)&$expand=Category_Restaurant!($select=Id,CategoryId,RestaurantId;$filter=(RestaurantId%20eq%201)),Category_ProductPosition($select=Id,CategoryId,ProductPositionId;$expand=ProductPosition($select=Position;$expand=Product_ProductPosition($select=Id,ProductPositionId,ProductId;$expand=Product($select=Id,Name,Description,AlergyInfo,ImageBig,ImageSmall,Price,Active,Upsell;$expand=Product_Restaurant!($select=ProductId,RestaurantId;$filter=(RestaurantId%20eq%201)),Product_OptionGroup($select=Id,ProductId,OptionGroupId;$expand=OptionGroup($select=Id,Name,SelectionType;$expand=OptionGroup_Option($select=Id,OptionGroupId,OptionId;$expand=Option($select=Id,Name,Price;$expand=Option_Restaurant!($select=OptionId,RestaurantId;$filter=(RestaurantId%20eq%201))))))))))&$select=Id,Name,Position,Active,Description,MonActive,MonStartHour,MonStartMinute,MonEndHour,MonEndMinute,TueActive,TueStartHour,TueStartMinute,TueEndHour,TueEndMinute,WedActive,WedStartHour,WedStartMinute,WedEndHour,WedEndMinute,ThuActive,ThuStartHour,ThuStartMinute,ThuEndHour,ThuEndMinute,FriActive,FriStartHour,FriStartMinute,FriEndHour,FriEndMinute,SatActive,SatStartHour,SatStartMinute,SatEndHour,SatEndMinute,SunActive,SunStartHour,SunStartMinute,SunEndHour,SunEndMinute&$orderby=Position")
        .headers(headers_odata)
      .check(status.is(200)),
      http("[Order] - 2c - Lazy load small image 1")
        .get("/api/v1/Product/39/ImageSmallDownload")
        .headers(headers_image)
      .check(status.is(200)),
      http("[Order] - 2d - Lazy load small image 2")
        .get("/api/v1/Product/3/ImageSmallDownload")
        .headers(headers_image)
      .check(status.is(200)))
    .check(status.is(404)))

.pause(4)`
}