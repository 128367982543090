import React from 'react'
import { List } from 'immutable'
import { CompetenceCard } from './competenceCard'
import { CompetenceGroup, competenceArray } from '../competences/index'

import '../css/CompetenceIndex.css'

const competences = List<CompetenceGroup>(competenceArray)

export const CompetenceIndex = (): JSX.Element => (
  <section id="competence-index">
    <h1>Competence index</h1>
      {competences.map((group, key) => (
        <div key={key} className="competence-group">
          <span className="competence-group-name">{group.name}</span>
          <div className="card-container">
          {group.c.map((c, key) => (
            <CompetenceCard key={key} title={c.code} to={c.to} />
          ))}
          </div>
        </div>
      ))}
  </section>
)