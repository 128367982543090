import React from 'react'
import { Carousel } from '../../components/carousel'
import { Link } from 'react-router-dom'
import { Notice } from '../../components/notice'
import { Competence } from '../index'

import SDBusinessAccount0 from './images/Business accounts - SD (0).jpg'
import SDBusinessAccount1 from './images/Business accounts - SD (1).jpg'
import SDBusinessAccount2 from './images/Business accounts - SD (2).jpg'
import SDBusinessAccount3 from './images/Business accounts - SD (3).jpg'

type O2Props = {
  linked_competences: Competence['linked_competence']
}

export const O2 = (p: O2Props) => (
  <>
    <section>
      <h1>Context</h1>
      <p>
        For <Link to="/competence/O1">O1</Link>, I created a sequence diagram for the business accounts
        in <strong>De Beren</strong> project. In this learning goal, I'll improve the design incrementally 
        with the feedback of Cristina.
      </p>
      <Notice type={'info'}>
        This competence is linked with {p.linked_competences !== undefined ? p.linked_competences.name : '#'}. Check <Link to={p.linked_competences !== undefined ? p.linked_competences.to : '#'}>{p.linked_competences !== undefined ? p.linked_competences.name : '#'}</Link> for the initial design.
      </Notice>
    </section>
    <section>
      <h1>Evidence</h1>
      <p>
        <strong>Figure 2</strong> is the second iteration of the sequence diagram. The change is subtle, but
        if you compare <strong>figure 1</strong> with <strong>figure 2</strong>, you can see that I changed 
        the text above the arrows to funcion names. This is done to keep the consistency with the code; you
        already know what the function is named, so you can look it up more easily.
      </p>
      <p>
        I also improved the alternate cases. In figure 2, I put the cases inside the boxes themselves instead of 
        where you're supposed to define the <code>alt</code>.
      </p>
      <Notice type={'info'}>
        You can click on the figures to compare the designs.
      </Notice>
      <Carousel name="sd-0" src={[
        {
          src: SDBusinessAccount0,
          caption: 'Figure 1. First iteration of the sequence diagram.',
          alt: 'Sequence Diagram 1'
        },        {
          src: SDBusinessAccount1,
          caption: 'Figure 2. Second iteration of the sequence diagram.',
          alt: 'Sequence Diagram 2'
        }
      ]} caption="Figure 1 & 2. First and second iteration of the sequence diagram" />

      <p>
        For the next improvement, I removed the database lifeline as it was not used. This make the diagram
        less cluttered. I also forgot to add a message to the <code>Registerd user</code> lifeline.
      </p>
      <Carousel name="sd-1" src={[
        {
          src: SDBusinessAccount1,
          caption: 'Figure 2. Second iteration of the sequence diagram.',
          alt: 'Sequence Diagram 2'
        },        {
          src: SDBusinessAccount2,
          caption: 'Figure 3. Third iteration of the sequence diagram.',
          alt: 'Sequence Diagram 3'
        }
      ]} caption="Figure 2 & 3. Second and third iteration of the sequence diagram" />

      <p>
        Looking at the sequence diagram, Cristina and I realized that the email service <strong>Selligent</strong> could
        return an error, but in the code we don't act on the error if it occurs. This was a really good find according
        to Cristina and I added the case in the last figure (figure 4).
      </p>
      <Carousel name="sd-2" src={[
        {
          src: SDBusinessAccount2,
          caption: 'Figure 3. Third iteration of the sequence diagram.',
          alt: 'Sequence Diagram 3'
        },        {
          src: SDBusinessAccount3,
          caption: 'Figure 4. Fourth iteration of the sequence diagram.',
          alt: 'Sequence Diagram 4'
        }
      ]} caption="Figure 3 & 4. First and second iteration of the sequence diagram" />
    </section>
    <section>
      <h1>Feedback</h1>
      <p>
        In the feedback Cristina thought that it was almost perfect, I missed an arrow that was pointing
        in the wrong direction which I corrected.
      </p>
    </section>
    <section>
      <h1>Reflection</h1>
      <p>
        The sequence diagram wasn't really difficult, I even found a flaw in the design. It was really
        fun to create a sequence diagram and see it come to life in the form of code.
      </p>
      <p>
        Making these kinds of diagrams also gives perspective how important it is to prepare and document.
      </p>
    </section>
  </>
)