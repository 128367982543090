import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css';

import '../css/Image.css'

export type ImageProps = {
  className?: string
  name: string
  src: string
  alt?: string
  caption?: string
}

export const Image = (props: ImageProps): JSX.Element => {
  const [isLightboxOpen, toggleLighbox] = useState(false)

  return (
    <>
      <div style={{ margin: 0 }}>
        <div className={`ratio-container ratio-container-${props.name} unknown-ratio-container`}  onClick={() => toggleLighbox(true)}>
          <img className="section-img lazyload blur-up" data-src={props.src} alt={props.alt} />
        </div>
        {props.caption
          ? <span className="image-caption">{props.caption}</span>
          : <></>}
      </div>
      {isLightboxOpen && (
          <Lightbox
            mainSrc={props.src}
            onCloseRequest={() => toggleLighbox(false)}
            imageTitle={props.caption}
            clickOutsideToClose
          />
        )}
    </>
  )
}