import React, { FunctionComponent } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import Style from 'react-syntax-highlighter/dist/esm/styles/prism/tomorrow';

type CodeProps = {
  lang: 'json' | 'tsx' | 'csharp' | 'scala'
  caption?: string
}

export const Code: FunctionComponent<CodeProps> = (props) => (
  <>
    <SyntaxHighlighter language={props.lang} style={Style} customStyle={{ borderRadius: '5px' }} showLineNumbers>
      { props.children }
    </SyntaxHighlighter>
    {props.caption && <span className="image-caption">{ props.caption }</span>}
  </>
)