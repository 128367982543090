import React from 'react'

import { Header } from '../components/header'
import { CompetenceIndex } from '../components/competenceIndex'
import { People } from '../components/people'
import { Image } from '../components/image'

import Map from '../images/map.png'

export const Main = (): JSX.Element => (
  <>
    <Header size="max" />
    <main>
      <section>
        <h1 id="scroll-to-me">About Hoppinger</h1>
        <p>
          Hoppinger is a digital partner that helps company's and organisations to digitalize and
          innovate their business. Doing this, they help their partners maintain their 
          position in their market and keep making an impact on their clients and end users.
        </p>
        <p>
          It's a company that creates digital solutions with a 'best-tool-for-the-job' vision.
          They specialize in HTML5, React, .NET, Haskell and TypeScript.
        </p>
        <Image name="main-map" src={Map}/>
      </section>
      <section>
        <h1>About the internship</h1>
        <p>
          The internship entails:
          <ul>
            <li>Working in a team with scrum and daily stand-ups</li>
            <li>Participating in on-going and new projects</li>
            <li>Working in a professional environment</li>
            <li>Learning from seniors by pair-programming and workshops</li>
          </ul>
        </p>
      </section>
      <section>
        <h1>People</h1>
        <People/>
      </section>
      <CompetenceIndex />
    </main>
  </>
)