import React from 'react'
import { getAppendixLink } from '../index'

export const L2 = () => (
  <>
    <section>
      <h1>Context</h1>
      <p>
        Hoppinger uses a library, <code>widgets-for-react</code>, that they've build themselves.
        It leverages the power of React and conforms to the vision of how projects should be
        build according to the CTO and several seniors.
      </p>
      <p>
        What's really great about the library, is that it's really powerful and when used correctly,
        makes for some clean code. What really sucks is the tutorial to get new employees started.
        I struggled really hard with getting to understand how the library works, so that's why
        I wanted to write an improved documentation for the library by keeping the original documentation
        and writing a <strong>beginner's friendly</strong> documentation.
      </p>
    </section>
    <section>
      <h1>Evidence</h1>
      <p>
        I made the learning goal at the beginning of my internship knowing that I would have to master
        the library at some point. I thought that I could use my acquired knowledge to improve the 
        docs. Who better than someone who learned it the hard way and knows what is missing in the
        documentation?
      </p>
      <p>
        After I felt comfortable working with the library, I asked if I could be planned for a week
        and a half to write the beginner's friendly documentation. I spent most of this time digging
        through the compiled code and trying to understand how each component works and how it
        interacts with each other.
      </p>
      <p>
        I struggled with how the documentation would be structured, but eventually after much rewriting,
        I like to think I found a pretty solid way:
        <ol>
          <li><a target="blank" href={getAppendixLink('Realize')('L2')('1. Intro')}>Intro</a></li>
          <li><a target="blank" href={getAppendixLink('Realize')('L2')('2. Basic components')}>Basic components</a></li>
          <li><a target="blank" href={getAppendixLink('Realize')('L2')('3. Using components')}>Using components</a></li>
          <li><a target="blank" href={getAppendixLink('Realize')('L2')('4. Async')}>Async</a></li>
          <li><a target="blank" href={getAppendixLink('Realize')('L2')('5. Putting it all together')}>Putting it all together</a></li>
        </ol>
      </p>
      <p>
        I felt that to properly explain the flow and the <code>state</code> of the application, 
        was in person. Of course I can't be there explaining it in person, so I found the next best
        thing: <a target="blank" href={getAppendixLink('Realize')('L2')('WFR Slide show')}>a slideshow</a>!
        With this, I try to explain how the data flows and changes in a React application.
      </p>
    </section>
    <section>
      <h1>Feedback</h1>
      <p>
        Wim Jongeneel looked at my beginner's guide and provided feedback. He thought that the <q>visuals are nice</q>,
        that I did a good job explaining it and that it's a <q>very good addition to the docs</q>.
      </p>
      <p>
        He also misses the 'why' and 'how'. I should explain why we're using some components of the
        library and to try calling things by their technical name.
      </p>
    </section>
    <section>
      <h1>Reflection</h1>
      <p>
        Although I'm happy wth how the documentation turned out, I know that it wouldn't be perfect
        which was what I was aiming for. Wim is completely right and his feedback shows why his 
        knowledge is superior in comparison to mine.
      </p>
      <p>
        Several colleagues asked when I would be done with my documentation which put positive pressure
        on me to write the best documentation I could. I didn't want to let them down.
      </p>
      <p>
        Writing documentation is something I definitely want to do more, since it helps me understand the 
        subject better and I take enjoyment in explaining things, be it in person or via a medium.
      </p>
    </section>
  </>
)