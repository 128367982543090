import React from 'react'

export const S1S2 = () => (
  <>
    <section>
      <h1>Context</h1>
      <p>
        I worked with the following tools:
        <ul>
          <li>Task tracking</li>
          <ul>
            <li>Trello</li>
            <li>Jira</li>
          </ul>
          <li>Simplicate</li>
          <li>Forecast</li>
          <li>Github</li>
          <li>Azure DevOps</li>
          <li>Kubernetes</li>
        </ul>
      </p>
    </section>
    <section>
      <h1>Evidence</h1>
      <p>
        I worked with Trello for several months, and with Jira for the last month or so.
        I tracked in both of them the cards I was working on and what the status was. This
        could be any problems that arised, new information or questions for the product owner.
      </p>
      <p>
        Simplicate and Forecast go hand in hand. Forecast is a tool used by project managers
        to plan employees in. So Forecast is where I would check when I needed to work. Simplicate
        is a tool for employees to log their hours. This is where I tracked the hours I worked with
        a description of the work I did to justify my hours being spend on projects.
      </p>
      <p>
        Github speaks for itself. It's the central place where Hoppinger's code was being stored.
        Azure DevOps was our continious integration (CI) tool. Here we could check the status
        of the deployment and redeploy or re-roll a previous deployment if needed. Kubernetes 
        was our infrastructure in which Hoppinger hosts all their projects for her clients.
      </p>
    </section>
    <section>
      <h1>Feedback</h1>
      <p>
        Jim gave really helpful feedback. He mentioned that I could work on the <q>total hygiene</q>
        of the cards in Trello and Jira. I sometimes forgot to mention or assign the correct person, or
        move the card to the correct lane.
      </p>
      <p>
        He also gave me a great tip: send a slack / email with the "highlights" of what was talked about
        to the person you're talking with.
      </p>
    </section>
    <section>
      <h1>Reflection</h1>
      <p>
        I always forgot to mention / assign the correct person and move the card to the correct lane.
        This is something I really struggled with for some reason. I guess I wasn't used to write
        code <strong>and</strong> keep cards up to date. This is something I improved over time, but the 
        mentioning / assigning part is an area I still lack in.
      </p>
      <p>
        The tip is a great one I wouldn't even think of. I feel like I did a version of this towards the end
        of my internship with another project manager, but not as consice with "highlights" like Jim said. Something 
        I'll definitely will bring to where ever I end up.
      </p>
    </section>
  </>
)