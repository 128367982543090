export const code = {
  selligent_example:
`{
  "items": [
    {
      "recipient": "d.coppens@brandpulse.nl",
      "language": "NL",
      "data": {
        "FIRSTNAME": "Dennis",
        "LASTNAME": "Coppens",
        "EXPECTED_DELIVERY_TIME_A": "17:13", // format HH:mm, when delivery is true
        "EXPECTED_DELIVERY_TIME_B": "17:28", // format HH:mm, when delivery is true
        "DELIVERY_COSTS": 1.50,
        "TOTAL_PRICE": 24.40,
        "ORDERNUMBER": "839854", // unique order number
        "STREET": "Landjuweel 28",
        "ZIPCODE": "2340",
        "CITY": "Vlimmeren",
        "TELEPHONE": "0498202020",
        "DELIVERY_TIME": "Zo snel mogelijk",
        "PAYMENT": "Online",
        "REMARK": "-",
        "AFFILIATE_ID": 20, // required POS4 affiliate ID
        "AFFILIATE_NAME": "Rotterdam Noord",
        "AFFILIATE_STREET": "Walenburgerweg 89c",
        "AFFILIATE_ZIPCODE": "3039 AE",
        "AFFILIATE_CITY": "Rotterdam",
        "AFFILIATE_TELEPHONE": "010-1234567",
        "AFFILIATE_EMAIL": "barendrecht@bezorgbeer.nl",
        "OPENINGHOURS": "Maandag t/m zondag van 11 tot 23 uur",
        "ORDERLINES": [
          {
            // image is 71x71px
            "IMAGE_URL":"https://deberen.slgnt.eu/images/DeBeren/Transactional/Bestellingen/EDM_system_desk_DeBeren_Delivery_01_23.png",
            
            "AMOUNT": 10,
            "NAME": "Rotterdamscher burger - seizoensburger",
            "EXTRAS": "Franse frites (met fritessaus)<br/>Appelmoes<br/>Geen besteksetje<br/>Geen frisdrank<br/>Geen mousse<br/>",
            "PRICE": 12.95,
            "PRODUCT_ID": 45221
          },
          {
            "IMAGE_URL":"https://deberen.slgnt.eu/images/DeBeren/Transactional/Bestellingen/EDM_system_desk_DeBeren_Delivery_01_26.png",
            "AMOUNT": 1,
            "NAME": "Kipsalade groot",
            "EXTRAS": "Dressing<br/>Geen besteksetje<br/>Geen frisdrank<br/>Geen mousse",
            "PRICE": 9.95,
            "PRODUCT_ID": 1242
          }
        ],
        "ORDER_DT": "2018-01-09T16:43:00", // ISO 8601 format
        "NEWSLETTER": true,
        "DELIVERY": true, // delivery or pickup
        "EXPECTED_PICKUP_DT": "1985-11-20T11:58:31" // ISO 8601 format, when delivery is false
      }
    }
  ]
}`,
 send_order_conf_first_version:
`public async Task OrderConfirmationEmail(Order order)
{
    Console.WriteLine("");
    Console.WriteLine("[### SelligentUserUpdateService ###]: OrderConfirmationEmail()");
    Console.WriteLine($"[### SelligentUserUpdateService ###]: Restaurant count: {order.Order_Restaurants.Count}");
    Console.WriteLine($"[### SelligentUserUpdateService ###]: Order items count: {order.Order_OrderItems.Count}");
    Console.WriteLine("");

    var restaurant = order.Order_Restaurants.Select(or => or.Restaurant).FirstOrDefault();
    var payMethod = order.Order_PayMethods.Select(opm => opm.PayMethod).FirstOrDefault();
    var deliveryTimes = restaurant.Restaurant_DeliveryTimess.Select(rdt => rdt.DeliveryTimes).FirstOrDefault();
    var PickupTimes = restaurant.Restaurant_PickupTimess.Select(rpt => rpt.PickupTimes).FirstOrDefault();
    var waitingTimes = restaurant.Restaurant_WaitingTimess.Select(rwt => rwt.WaitingTimes).FirstOrDefault();

    Console.WriteLine($"[### SelligentUserUpdateService ###]: {restaurant}");
    Console.WriteLine($"[### SelligentUserUpdateService ###]: {payMethod}");
    Console.WriteLine($"[### SelligentUserUpdateService ###]: {deliveryTimes}");
    Console.WriteLine($"[### SelligentUserUpdateService ###]: {PickupTimes}");
    Console.WriteLine($"[### SelligentUserUpdateService ###]: {order.Order_Restaurants.Select(or => or.Restaurant).FirstOrDefault().Restaurant_WaitingTimess.Select(rwt => rwt.WaitingTimes).FirstOrDefault()}");

    foreach (Restaurant_WaitingTimes rwt in restaurant.Restaurant_WaitingTimess)
    {
        Console.WriteLine($"[### SelligentUserUpdateService ###]: Restaurant: {rwt.Restaurant}");
        Console.WriteLine($"[### SelligentUserUpdateService ###]: Waiting times {rwt.WaitingTimes}");
    }

    if (restaurant != null && payMethod != null && deliveryTimes != null && PickupTimes != null) {
        var message = new Message<OrderConfirmationData>();
        var data = new OrderConfirmationData();
        message.items = new List<BaseData<OrderConfirmationData>>();
        data.FIRSTNAME = order.Name;
        data.LASTNAME = order.Name;
        data.ORDERNUMBER = order.ReferenceNumber;

        data.STREET = order.Street;
        data.ZIPCODE = order.Postcode;
        data.CITY = order.City;
        data.TELEPHONE = order.Phone;
        data.DELIVERY_TIME = order.DeliveryOrPickupTime.ToString();
        data.REMARK = order.Comments;

        data.TOTAL_PRICE = 0;
        foreach (Order_OrderItem oi in order.Order_OrderItems)
        {
            Console.WriteLine($"[### SelligentUserUpdateService ###]: Item: {oi.OrderItem.UnitPrice}");
            data.TOTAL_PRICE = data.TOTAL_PRICE + (oi.OrderItem.UnitPrice * oi.OrderItem.Quantity);
        }

        // Payment method
        data.PAYMENT = payMethod.DisplayTitle;

        // Restaurant
        data.DELIVERY_COSTS = restaurant.DeliveryCost;
        data.AFFILIATE_ID = restaurant.Id;
        data.AFFILIATE_NAME = restaurant.Name;
        data.AFFILIATE_STREET = restaurant.Address;
        data.AFFILIATE_ZIPCODE = restaurant.PostCode;
        data.AFFILIATE_CITY = restaurant.City;
        data.AFFILIATE_TELEPHONE = restaurant.PhoneNumber;
        data.AFFILIATE_EMAIL = restaurant.EmailAddress;
        // TODO: How to do the expected time? => DONE
        // For delivery do => A = DeliveryOrPickupTime and B = DeliveryOrPickupTime + max expected delivery
        // for pickup do => a = DeliveryOrPickupTime and B = DeliveryOrPickupTime + max expected pickup
        data.EXPECTED_DELIVERY_TIME_A = $"{order.DeliveryOrPickupTime.Hour}:{order.DeliveryOrPickupTime.Minute}";
        data.EXPECTED_DELIVERY_TIME_B = $"{order.DeliveryOrPickupTime.Hour}:{order.DeliveryOrPickupTime.Minute}";
        // data.EXPECTED_DELIVERY_TIME_B = $"{order.DeliveryOrPickupTime.AddMinutes(order.Pickup ? waitingTimes.PickupTimeMax : waitingTimes.DeliveryTimeMax)}";

        // TODO: Openinghours
        // Now it: 'Vandaag zijn we van {time} tot {time} open'
        // We have delivery and pickup times, which of the two do we choose?
        // We could do the whole week in a list, so:
        // Maandag - 8:00 - 22:00
        // Dinsdag - 8:00 - 22:00
        // enz
        data.OPENINGHOURS = $"Vandaag zijn we van ... tot ... open";

        string extras = "";
        foreach (Order_OrderItem oi in order.Order_OrderItems)
        {
            foreach (OrderItem_OrderItemOption oioio in oi.OrderItem.OrderItem_OrderItemOptions)
            {
                foreach (OrderItemOption_Option oioo in oioio.OrderItemOption.OrderItemOption_Options)
                {
                    // oioo.Option.
                }
            }
        }

        data.ORDERLINES = new List<SelligentOrder>();
        foreach (Order_OrderItem oi in order.Order_OrderItems)
        {
            foreach (OrderItem_OrderItemProduct oioip in oi.OrderItem.OrderItem_OrderItemProducts)
            {
                foreach (OrderItemProduct_Product oipp in oioip.OrderItemProduct.OrderItemProduct_Products)
                {
                    Console.WriteLine("");
                    Console.WriteLine($"[### SelligentUserUpdateService ###]: Product ID: {oipp.Product.Id}");
                    Console.WriteLine($"[### SelligentUserUpdateService ###]: Product name: {oipp.Product.Name}");
                    Console.WriteLine($"[### SelligentUserUpdateService ###]: Product price: {oi.OrderItem.UnitPrice}");
                    Console.WriteLine($"[### SelligentUserUpdateService ###]: Product quantity: {oi.OrderItem.Quantity}");
                    Console.WriteLine($"[### SelligentUserUpdateService ###]: Product image url: \\"data:image/jpeg;base64,{oipp.Product.Image}\\"");
                    Console.WriteLine("");
                    data.ORDERLINES.Add(
                        new SelligentOrder() {
                            PRODUCT_ID = oipp.Product.Id,
                            NAME = oipp.Product.Name,
                            AMOUNT = oi.OrderItem.Quantity,
                            PRICE = oi.OrderItem.UnitPrice,
                            EXTRAS = "",
                            // FIXME: Assign empty string when there's nothing in the database
                            IMAGE_URL = oipp.Product.Image != "" ? $"data:image/jpeg;base64,{oipp.Product.Image}" : ""
                        }
                    );
                }
            }
        }

        // Order_DT is the date of today
        data.ORDER_DT = order.CreatedDate.ToString();
        data.NEWSLETTER = false;
        data.DELIVERY = !order.Pickup;
        data.EXPECTED_PICKUP_DT = order.Pickup ? order.DeliveryOrPickupTime.ToString() : "";

        message.items.Add(
            new BaseData<OrderConfirmationData>() {
                recipient = order.Email,
                language = "NL",
                data = data
            }
        );

        string payload = JsonConvert.SerializeObject(message);
        Console.WriteLine("");
        Console.WriteLine($"[### SelligentUserUpdateService ###]: payload: {payload}");
        Console.WriteLine("");
        await _mailService.SendEmailWithSelligentAsync("ORDER_ACC/send", payload);
    }
}`,
  send_order_conf_func:
`public async Task OrderConfirmationEmail(Order order)
{
    // Gets the data. We know there's always one. With this, we don't have to loop.
    var restaurant = order.Order_Restaurants.Select(or => or.Restaurant).FirstOrDefault();
    var payMethod = order.Order_PayMethods.Select(opm => opm.PayMethod).FirstOrDefault();
    var deliveryTimes = restaurant.Restaurant_DeliveryTimess.Select(rdt => rdt.DeliveryTimes).FirstOrDefault();
    var pickupTimes = restaurant.Restaurant_PickupTimess.Select(rpt => rpt.PickupTimes).FirstOrDefault();
    var waitingTimes = restaurant.Restaurant_WaitingTimess.Select(rwt => rwt.WaitingTimes).FirstOrDefault();

    // We can't send an email if one of there is null
    if (restaurant != null && payMethod != null && deliveryTimes != null && pickupTimes != null)
    {
        await CreateSelligentOrder(order, restaurant, payMethod, deliveryTimes, pickupTimes, waitingTimes);
    }
}`
}