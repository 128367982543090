import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css';

type srcType = {
  src: string
  alt: string
  caption: string
}

export type CarouselProps = {
  className?: string
  name: string
  src: srcType[]
  caption?: string
}

export const Carousel = (props: CarouselProps) => {
  const [index, setIndex] = useState(0)
  const [isCarouselOpen, toggleCarousel] = useState(false)

  return (
    <>
      <div style={{ margin: 0 }}>
        <div className={`ratio-container ratio-container-${props.name} unknown-ratio-container`}  onClick={() => toggleCarousel(true)}>
          <img className="section-img lazyload blur-up" data-src={props.src[0].src} alt={props.src[0].alt} />
        </div>
        {props.caption
          ? <span className="image-caption">{props.caption}</span>
          : <></>}
      </div>
      {isCarouselOpen && 
        <Lightbox
          mainSrc={props.src[index].src}
          nextSrc={props.src[(index + 1) % props.src.length].src}
          prevSrc={props.src[(index + props.src.length - 1) % props.src.length].src}
          imageTitle={props.src[index].caption}
          onCloseRequest={() => toggleCarousel(false)}
          onMovePrevRequest={() => setIndex((index + props.src.length - 1) % props.src.length)}
          onMoveNextRequest={() => setIndex((index + 1) % props.src.length)}
        />}
    </>
  )
}